import React from "react";

import { ConsultationApi } from "@qmarketing/b2c-js-sdk";

import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";

const StyledRating = withStyles({
  iconFilled: {
    color: "#009fe3",
  },
  iconHover: {
    color: "#009fe3",
  },
})(Rating);

const sendRating = async (data) => {
  try {
    await new ConsultationApi()
      .apiConsultationIdRatingPost(data.id, { rating: data.stars })
      .then(
        (data) => {
          console.log("API called successfully. Returned data: " + data);
        },
        (error) => {
          console.error(error);
        }
      );
  } catch (err) {
    console.error(err);
  }
};

export default function StarRating({ id, rating }) {
  const [value, setValue] = React.useState(rating);
  const setRating = (value) => {
    setValue(value);
    sendRating({
      id: id,
      stars: value,
    });
  };
  return (
    <div>
      <Box component="fieldset" mb={3} borderColor="transparent">
        <StyledRating
          name="simple-controlled"
          value={value}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
        />
      </Box>
    </div>
  );
}
