import React from "react";
import { imgaes } from "../static/data";

// import LoopInfo from "../LoopInfo";

export default function ChapterData({ chapter, fields, fieldValues }) {
  //   const options = { weekday: "long", month: "long", day: "numeric" };
  //   let seminarDate;
  //   let hasSeminar;
  //   if (
  //     chapter.seminarAppointment.value !== null &&
  //     chapter.seminarAppointment.value !== "" &&
  //     chapter.seminarAppointment.value !== "0001-01-01T00:00:00Z"
  //   ) {
  //     hasSeminar = true;
  //     seminarDate = new Date(data.seminarAppointment.value).toLocaleDateString(
  //       "de-DE",
  //       options
  //     );
  //   }

  return (
    <section id={chapter.key}>
      <header>
        <h2>{chapter.name}</h2>
        <span className="image main">
          <img src={imgaes[chapter.key]} alt="" />
        </span>
      </header>
      <div className="content mt-md-5">
        {fields.map((field) => {
          switch (field.fieldType.dataType) {
            case "string":
              const id = `${chapter.key}_${field.key}`;
              const fieldValue = fieldValues?.find(
                (fieldValue) => fieldValue.field.id === field.id
              );
              if (!fieldValue) {
                return "";
              }
              return (
                <div key="string" className="q-collapse-wrapper">
                  <div className="q-collapse w-100 d-flex flex-column">
                    <a
                      className="q-collapse-link d-flex w-100 collapsed"
                      data-toggle="collapse"
                      href={`#${id}`}
                      role="button"
                      aria-expanded="false"
                      aria-controls={id}
                    >
                      <span className="q-close-button-wrapper ">
                        <span className="q-close-button"></span>
                      </span>
                      Notizen
                    </a>
                    <div className="collapse pt-3" id={id}>
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {fieldValue.value}
                      </p>
                      {/* <button className="q-btn q-btn-primary q-small">
                        Noch Fragen?
                      </button> */}
                    </div>
                  </div>
                </div>
              );
            default:
              return "";
          }
        })}

        {/* <div className="q-collapse-wrapper">
          <div className="q-collapse w-100 d-flex flex-column">
            <a
              className="q-collapse-link d-flex w-100"
              data-toggle="collapse"
              href="#buschwelcomeOrders"
              role="button"
              aria-expanded="true"
              aria-controls="buschwelcomeOrders"
            >
              <span className="q-close-button-wrapper ">
                <span className="q-close-button"></span>
              </span>
              Ihre Verkaufsunterstützung
            </a>
            <div className="collapse show pt-3" id="buschwelcomeOrders">
              <ul>
                <LoopInfo data={data} />
              </ul>
            </div>
          </div>
        </div>

        {hasSeminar && (
          <div className="q-collapse-wrapper">
            <div className="q-collapse w-100 d-flex flex-column">
              <a
                className="q-collapse-link d-flex w-100 collapsed"
                data-toggle="collapse"
                href="#buschwelcomeSeminar"
                role="button"
                aria-expanded="false"
                aria-controls="buschwelcomeSeminar"
              >
                <span className="q-close-button-wrapper ">
                  <span className="q-close-button"></span>
                </span>
                Seminar am {seminarDate}
              </a>
              <div className="collapse pt-3" id="buschwelcomeSeminar">
                <h3>{seminarDate}</h3>
                <p>{data.seminarNote.value}</p>
              </div>
            </div>
          </div>
        )}

        {data.notes !== "" && (
          <div className="q-collapse-wrapper">
            <div className="q-collapse w-100 d-flex flex-column">
              <a
                className="q-collapse-link d-flex w-100 collapsed"
                data-toggle="collapse"
                href="#buschwelcomeNotes"
                role="button"
                aria-expanded="false"
                aria-controls="buschwelcomeNotes"
              >
                <span className="q-close-button-wrapper ">
                  <span className="q-close-button"></span>
                </span>
                Notizen
              </a>
              <div className="collapse pt-3" id="buschwelcomeNotes">
                <p style={{ whiteSpace: "pre-wrap" }}>{data.notes.value}</p>
                <button className="q-btn q-btn-primary q-small">
                  Noch Fragen?
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="q-collapse-wrapper">
          <div className="q-collapse w-100 d-flex flex-column">
            <a
              className="q-collapse-link d-flex w-100 collapsed"
              data-toggle="collapse"
              href="#buschwelcomeLinks"
              role="button"
              aria-expanded="false"
              aria-controls="buschwelcomeLinks"
            >
              <span className="q-close-button-wrapper ">
                <span className="q-close-button"></span>
              </span>
              Weiterführende Links
            </a>
            <div className="collapse pt-3" id="buschwelcomeLinks">
              <p className="mb-2">
                <a
                  href="https://busch-jaeger-konfigurator.de/de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  » Busch-Welcome® Konfigurator
                </a>
              </p>
              <p className="mb-2">
                <a
                  href="https://www.busch-jaeger.de/busch-welcome"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  » Busch-Jaeger Website
                </a>
              </p>
            </div>
          </div>
        </div> */}

        <div className="pt-4">
          <p>
            <a
              href={`${process.env.REACT_APP_PLAYBOOK_URI}/${chapter.path}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Präsentation aus Gespräch erneut ansehen
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}
