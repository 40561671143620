import React from "react";
import ReactDOM from "react-dom";
import "./assets/sass/bje/style.sass";
import "./assets/sass/html5up/main.scss";
import "./index.css";
import "react-activity/dist/react-activity.css";
import "bootstrap/dist/css/bootstrap.min.css";
//eslint-disable-next-line no-unused-vars
import $ from "jquery";
//eslint-disable-next-line no-unused-vars
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as B2CSDK from "@qmarketing/b2c-js-sdk";

global.API = B2CSDK.ApiClient.instance;
global.API.basePath = process.env.REACT_APP_API_URL;

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();
