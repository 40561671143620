import React, {useCallback, useEffect, useState} from 'react'
import BJELogo from '../assets/img/Logo.svg'
import StarRating from './Rating.js'
import CircularIndeterminate from './CircularLoader.js'
import Header from './Header'
import Anchorlinks from './Anchorlinks.js'
import Footer from './Footer'
import NavBar from './NavBar'
import NextAppointment from './NextAppointment'
import DownloadPdf from './ExportPDF/ExportPDF'
import ProductRating from './ProductRating'

import {StateApi, ConsultationApi} from '@qmarketing/b2c-js-sdk'
import ChapterData from './ChaperData'

import lebensraeumeImg from '../assets/_titelbilder/lebensraeume.jpg'

export default function DashboardComponent({id}) {
  const [chapters, setChapters] = useState(null)
  const [consultation, setConsultation] = useState(null)

  const checkValidId = useCallback(() => {
    let cId = null

    const regex =
      /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/gm
    let m

    while ((m = regex.exec(id)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++
      }
      //eslint-disable-next-line no-loop-func
      m.forEach((match, groupIndex) => {
        cId = match
      })
    }

    return cId === id
  }, [id])

  const getChapters = useCallback(async () => {
    try {
      const data = await new StateApi().apiStateGet()

      setChapters(data.modules)
    } catch (err) {
      console.error(err)
    }
  }, [])

  const getConsultation = useCallback(async () => {
    try {
      const data = await new ConsultationApi().apiConsultationIdGet(id)

      console.log(data)
      setConsultation(data)
    } catch (err) {
      console.error(err)
    }
  }, [id])

  useEffect(() => {
    if (checkValidId()) {
      getConsultation()
      getChapters()
    } else {
      console.error('PROVIDE ID!')
    }
  }, [getConsultation, getChapters, checkValidId])

  if (!consultation || !chapters) {
    return (
      <div
        style={{alignSelf: 'center', margin: '0 auto'}}
        className="loadingScreen"
      >
        <div className="loader d-flex justify-content-center align-items-center">
          <CircularIndeterminate />
        </div>
        <div className="d-flex justify-content-center align-items-center mt-5 loader-text">
          <img src={BJELogo} alt="logo" /> |{' '}
          <p className="mb-0 ml-3">Busch-eDialog</p>
        </div>
      </div>
    )
  }

  const {consulting, consultant, customer, fieldValues} = consultation
  const {company} = consultant

  const options = {weekday: 'long', month: 'long', day: 'numeric'}
  const appointmentDate = new Date(consulting.appointment).toLocaleDateString(
    'de-DE',
    options,
  )

  const chaptersWithFields = chapters.filter(module => {
    let found = false
    module.fields.forEach(field => {
      const fieldValue = fieldValues?.find(
        fieldValue => fieldValue.field.id === field.id,
      )
      if (fieldValue) {
        found = true
      }
    })

    return found
  })

  return (
    <div key="dashboard">
      <NavBar />
      <main id="q-page-content">
        <Header
          appointmentDate={appointmentDate}
          customerFirstname={customer.firstname}
          customerLastname={customer.lastname}
          customerSalutation={customer.salutation}
          customerEmail={customer.email}
          admSalutation={consultant.salutation}
          admFirstname={consultant.firstname}
          admLastname={consultant.lastname}
        />
        <Anchorlinks
          chapters={chaptersWithFields}
          notes={consulting.notes !== ''}
          nextAppointment={
            consulting.next_appointment !== null &&
            consulting.next_appointment !== '0001-01-01T00:00:00Z' &&
            consulting.next_appointment !== ''
          }
        />
        <div id="wrapper">
          {chaptersWithFields.map(module => {
            return (
              <ChapterData
                key={module.chapter.key}
                chapter={module.chapter}
                fields={module.fields}
                fieldValues={fieldValues}
              />
            )
          })}

          <section id="contact">
            <header>
              <h2>Ihr Ansprechpartner</h2>
            </header>
            <div className="content">
              <ul className="list-unstyled">
                {company?.logo && (
                  <li>
                    <img
                      src={consultant.company.logo}
                      height="150"
                      alt="company_logo"
                      style={{paddingBottom: 20}}
                    />
                  </li>
                )}
                <li>
                  <strong>
                    {consultant.salutation} {consultant.firstname}{' '}
                    {consultant.lastname}
                  </strong>
                </li>
                <li>
                  <a
                    href="mailto:{result.settings.email}"
                    title="Öffnet ein Fenster zum Versenden der E-Mail"
                  >
                    {consultant.email}
                  </a>
                </li>
                <li>Tel: {consultant.phonenr}</li>
                <li>Fax: {consultant.faxnr}</li>
                {company && (
                  <>
                    <li>Firma: {company.name}</li>
                    <li>Straße: {company.street}</li>
                    <li>Ort: {`${company.postcode} ${company.city}`}</li>
                  </>
                )}
              </ul>
              <div>
                <div>
                  <a
                    className="q-btn mt-4 q-btn-primary q-small"
                    href={`mailto:${consultant.email}?subject=Busch%20eDialog:%20Kontaktaufnahme`}
                  >
                    Kontakt aufnehmen
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section id="rating">
            <header>
              <h2>
                Wie zufrieden sind Sie mit der neuen Form des
                Beratungsgesprächs.
              </h2>
            </header>
            <div className="content">
              <div className="row">
                <div className="col-12">
                  {consulting.rating === 0 ? (
                    <p className="mb-1">
                      <strong>Ihre Meinung zum Beratungsgespräch</strong>
                    </p>
                  ) : (
                    <p className="mb-1">
                      <strong>Vielen Dank für Ihre Bewertung</strong>
                    </p>
                  )}
                  <StarRating id={id} rating={consulting.rating} />
                </div>
              </div>
            </div>
          </section>

          {consultation.chapterRatings && (
            <section id="rating">
              <header>
                <h2>Produkte bewerten</h2>
              </header>
              <div className="content">
                {consultation.chapterRatings.map(chapterRating => {
                  const chapter = chapters.find(
                    el => el.chapter.key === chapterRating.chapter_name,
                  )?.chapter

                  if (!chapter) {
                    return ''
                  }

                  return (
                    <div className="row" key={chapterRating.chapter_name}>
                      <div className="col-12">
                        <p className="mb-1">
                          <strong>{chapter.name}</strong>
                        </p>
                        <ProductRating
                          id={id}
                          productRating={consultation.chapterRatings}
                          prodId={chapterRating.chapter_name}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </section>
          )}

          <section id="lebensraeume">
            <header>
              <h2>Konfigurator</h2>
            </header>
            <div className="content">
              <div className="row">
                <div className="col-12">
                  <img src={lebensraeumeImg} alt="lebensraeume" width={450} />
                </div>
                <div className="col-12">
                  <p className='mt-4'>
                    Planen Sie hier Ihr individuelles SmartHome mit unserem
                    Konfigurator
                  </p>
                </div>
                <div className="col-12">
                  <a
                    className="q-btn mt-4 q-btn-primary q-small"
                    href="https://lebensraeume.busch-jaeger.de/#/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Zum Konfigurator
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section id="profile">
            <header>
              <h2>Ihre Kontaktdaten</h2>
            </header>
            <div className="content">
              <ul className="list-unstyled">
                <li>
                  <strong>
                    {customer.salutation} {customer.firstname}{' '}
                    {customer.lastname}
                  </strong>
                </li>
                <li>
                  <a
                    href="mailto:{result.settings.email}"
                    title="Öffnet ein Fenster zum Versenden der E-Mail"
                  >
                    {customer.email}
                  </a>
                </li>
              </ul>
              <a
                className="q-btn mt-4 q-btn-primary q-small"
                href={`mailto:${consultant.email}?subject=Busch%20eDialog:%20Aktualisierung%20Kontaktdaten`}
              >
                Aktualisierung mitteilen
              </a>
            </div>
          </section>
          {consulting.next_appointment !== null &&
            consulting.next_appointment !== '0001-01-01T00:00:00Z' &&
            consulting.next_appointment !== '' && (
              <NextAppointment consulting={consulting} adm={consultant} />
            )}
          <section id="pdf">
            <div className="content">
              <DownloadPdf
                chapters={chaptersWithFields}
                consultation={consultation}
              />
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  )
}
