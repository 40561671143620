import BGHeaderMissionToZero from "../assets/_titelbilder/missiontozero.jpg";
import BGHeaderDoorCommunication from "../assets/_titelbilder/tuerkommunikation.jpg";
import BGHeaderSmarterHome from "../assets/_titelbilder/smarter-home.jpg";
import BGHeaderSwitchControl from "../assets/_titelbilder/schalten-und-steuern.jpg";

export const imgaes = {
    missiontozero: BGHeaderMissionToZero,
    doorcommunication: BGHeaderDoorCommunication,
    smarterhome: BGHeaderSmarterHome,
    switchcontrol: BGHeaderSwitchControl,
  };