import React, { Component } from "react";
import $ from "jquery";

class Anchorlinks extends Component {
  constructor(props) {
    super(props);
    this.createSticky = this.createSticky.bind(this);
  }

  componentDidMount() {
    this.createSticky($("#q-anchorlinks"));
    $('.q-anchorlinks a[href^="#"]').on("click", function (e) {
      // e.preventDefault();

      var target = this.hash,
        $target = $(target);

      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: $target.offset().top - 200,
          },
          200,
          "swing",
          function () {
            window.location.hash = target;
          }
        );
    });
  }

  createSticky(sticky) {
    if (typeof sticky !== "undefined") {
      let pos = sticky.offset().top - 65,
        win = $(window);

      win.on("scroll", function () {
        win.scrollTop() >= pos
          ? sticky.addClass("fixed")
          : sticky.removeClass("fixed");
      });
    }
  }

  render() {
    const { chapters, nextAppointment } = this.props;

    return (
      <div className="anchor-spacing">
        <div className="container-fluid q-anchorlinks" id="q-anchorlinks">
          <div className="container container-lg">
            <div className="row">
              <div className="col-12">
                <nav
                  id="anchors"
                  className="navbar q-anchorlinks sticky-top p-0"
                >
                  <ul className="nav inline d-flex">
                    {chapters.map((chapter) => {
                      return (
                        <li key={chapter.chapter.key} className="nav-item">
                          <a className="nav-link" href={`#${chapter.chapter.key}`}>
                            » {chapter.chapter.name}
                          </a>
                        </li>
                      );
                    })}
                    {nextAppointment && (
                      <li className="nav-item">
                        <a className="nav-link" href="#date">
                          » Nächster Termin
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Anchorlinks;
