import React from 'react'

export default function NextAppointment({consulting, adm}) {
    const nextAppointmentDate = new Date(consulting.next_appointment).toLocaleDateString('de-DE', { weekday: 'long', month: 'long', day: 'numeric' })
    return (
        <section id="date">
            <header>
                <h2>
                    Nächster Termin
                </h2>
            </header>
            <div className="content">
                <h3 className="mb-0">{nextAppointmentDate}<br /></h3>
                <p>{consulting.next_appointment_place}</p>
                <a className="q-btn mt-4 q-btn-primary q-small" href={`mailto:${adm.email}?subject=Busch%20eDialog:%20Termin-Änderungswunsch`}>Änderungswunsch senden</a>
            </div>
        </section>
    )
}
