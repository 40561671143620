import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import { ConsultationApi } from "@qmarketing/b2c-js-sdk";

const StyledRating = withStyles({
  iconFilled: {
    color: "#009fe3",
  },
  iconHover: {
    color: "#009fe3",
  },
})(Rating);

// const sendRating = (data, products) => {
//     let newProd = products
//     newProd[data.prodId] = data.stars
//     let postData = {
//         "id": data.id,
//         "products": products,
//     }
//     let xhr = new XMLHttpRequest();

//     xhr.open("POST", process.env.REACT_APP_API_URL + "setproductrating");
//     xhr.setRequestHeader("Content-Type", "application/json");
//     xhr.send(JSON.stringify(postData));
// }

const sendRating = async (data, products) => {
  let newProds = products;
  let newProdIndex = newProds.findIndex(
    (el) => el.chapter_name === data.prodId
  );
  newProds[newProdIndex].rating = data.stars;

  try {
    await new ConsultationApi()
      .apiConsultationIdChapterratingsPost(data.id, newProds)
      .then(
        (data) => {
          console.log("API called successfully. Returned data: " + data);
        },
        (error) => {
          console.error(error);
        }
      );
  } catch (err) {
    console.error(err);
  }
};

export default function ProductRating({ id, productRating, prodId }) {
  const [value, setValue] = React.useState(
    productRating.find((el) => el.chapter_name === prodId)?.rating || 0
  );
  const setRating = (value) => {
    setValue(value);
    sendRating(
      {
        id: id,
        stars: value,
        prodId: prodId,
      },
      productRating
    );
  };
  return (
    <div>
      <Box component="fieldset" mb={3} borderColor="transparent">
        <StyledRating
          name={`${prodId}-rating`}
          value={value}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
        />
      </Box>
    </div>
  );
}
